//Imports Sable and Bootstraps mixins,variables and functions
@import "../helpers/_helpers-dir.scss";

.mtt-widget {
    .mtt-widget-list {

        &.count--three {
            margin: 0 auto;

            @include media-breakpoint-up(md) {
                margin: 0 auto;
                max-width: 70%;
            }

            @include media-breakpoint-up(lg) {
                max-width: 39%;
            }
        }

        &.count--five {
            max-width: 68%;
            margin: 0 auto;
        }

        .splide__slide {
            .mtt-widget-list-slide-container {
                overflow: hidden;
                filter: brightness(0.6);
                margin-top: 15px;
                text-align: center;
                width: 88%;
                margin: 15px auto;

                img {
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    border-radius: 8px;
                    margin-bottom: 0!important;
                    
                    @include media-breakpoint-up(md) {
                        width: 100%;
                        height: 100%;
                    }
                }

                .name-and-title {
                    bottom: 0;
                    width: 100%;
                    height: 80px;
                    background: #fff;
                    padding: 0.5em 1em;
                    position: absolute;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;

                    .title {
                        font-size: .725rem;
                    }
                }
            }

            &.is-active {
                border: none;

                .mtt-widget-list-slide-container {
                    -webkit-transition: all, 0.5s;
                    -moz-transition: all, 0.5s;
                    -o-transition: all, 0.5s;
                    transition: all, 0.5s;
                    filter: none;
                    margin: 0;
                    width: 100%;

                    .name-and-title {
                        bottom: -80px;
                        height: 0;
                        -webkit-transition: all, 0.5s;
                        -moz-transition: all, 0.5s;
                        -o-transition: all, 0.5s;
                        transition: all, 0.5s;
                    }
                }
            }
        }
    }
}

.mtt-widget-detail {

    @include media-breakpoint-up(md) {
        margin-top: 2rem;
    }

    .mtt-widget-detail-slide-container {
        border-radius: 8px;
        padding: 2em;
        width: 40%;
        margin-left: auto;
        margin-right: auto;

        @media only screen and (max-width: 980px) {
            width: 60%;
        }

        @media only screen and (max-width: 768px) {
            width: 80%;
        }
        
    }
}

.splide__arrow {
    background: transparent;
    width: 5em;
    opacity: 1;

    svg {
        width: 900px;
        height: 900px;
    }
}

.splide__arrow--prev {
    left: -2em;

    @include media-breakpoint-up(md) {
        left: -7em;
    }
}

.splide__arrow--next {
    right: -2em;

    @include media-breakpoint-up(md) {
        right: -7em;
    }
}

.splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
    border: none;
}
